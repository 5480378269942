search-filter {
  form {
    display: flex;
    justify-content: space-between;

    .mat-mdc-form-field .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex {
      display: flex;
      align-items: flex-end;
    }
  }
}
