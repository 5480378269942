assistance-dialog {
  .mat-mdc-dialog-content {
    max-height: 100%;
    .mdc-text-field--filled {
      background: transparent;
      padding-bottom: 10px;
      height: 40px;
      min-height: min-content;
    }

    .mat-mdc-tab-group {
      height: 100%;
      width: 100%;

      .mat-mdc-tab-label {
        flex: 1;

        .mat-mdc-progress-spinner {
          position: absolute;
          right: 20px;
        }
      }

      .mat-mdc-tab-body.mat-tab-body-active {
        height: 100%;
      }

      .mat-mdc-tab-body-wrapper .mat-mdc-tab-body .mat-mdc-tab-body-content {
        height: 100%;

        brand-selection {
          .mat-mdc-list {
            display: flex;
            flex-wrap: wrap;
            height: 390px;
            width: 100%;
            overflow: auto;

            .mat-mdc-list-item {
              margin-top: 10px;
              margin-bottom: 10px;
              width: 20%;
              display: block;

              .mat-mdc-raised-button {
                width: 100%;
                padding: 0 8px;
                justify-content: flex-start;

                .mdc-button__label {
                  display: flex;
                  align-items: center;
                }

                img {
                  width: 40px;
                  height: 40px;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
  .ngx-datatable {
    .datatable-body-row {
      cursor: pointer;
    }
    .datatable-body-row:hover {
      background-color: lighten(map-get($touch-accent, 300), 22%) !important;
    }

    .datatable-body-cell:hover {
      background-color: lighten(map-get($touch-accent, 300), 22%) !important;
    }
  }
}
