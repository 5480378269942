model-preview-dialog {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    width: 100%;
  }
}

.no-p {
  .mat-mdc-dialog-container {
    padding: 0;
  }
}
