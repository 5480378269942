printable-data-selection-dialog {
  .mat-mdc-dialog-content {
    display: flex;
    width: 100%;
    height: 550px;
    max-height: 550px;

    .dialog-editor {
      position: relative;
      background: #d6d6d6;
      width: 100%;
      height: 100%;
      cursor: pointer;
      overflow: hidden;
    }
  }
}
