.right-menu {
  display: inline-flex;
  flex: 0 1 223px;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  background-color: map-get($touch-background, 50);
  border-radius: 3px;
  box-shadow: 0px 2px 2px map-get($touch-background, 200);
  overflow-y: auto;

  .install-button {
    position: absolute;
    bottom: 10px;
    text-align: center;
    button {
      width: 210px;
    }
  }
  .mdc-text-field--filled {
    background-color: transparent !important;
    padding-bottom: 6px;
  }

  .mat-mdc-form-field-infix {
    height: 30px;
    min-height: min-content;
  }

  .reset-btn {
    min-height: max-content;
  }
  .reset-button {
    display: flex;
    justify-content: center;
  }
}

printable-data {
  flex: 0;
  min-height: max-content;
  height: auto;

  .list-item-img {
    display: flex;
    width: 40px;
    height: 35px;
    margin-right: 5px;
    align-items: center;

    img {
      zoom: 0.08;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

max-try {
  flex: 0;
  min-height: max-content;
  border-bottom: 1px solid #c3c1c1;
  padding-bottom: 12px;

  .printing-info {
    margin: 15px 0px;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    font-size: 12px;
    sup {
      font-size: 9px;
    }

    .max-printing {
      padding-right: 7px;
    }
  }
}

filter-menu-dumb,
printable-data {
  border-bottom: $touch-border;
  padding-bottom: 10px;
}

filter-menu-dumb {
  margin: 10px 5px;
  display: flex;
  flex-direction: column;
  min-height: max-content;
  text-align: center;
  form {
    .mat-mdc-form-field {
      width: 100%;
    }
    .mat-form-field-infix {
      height: 30px;
      min-height: min-content;
    }
  }

  .mat-mdc-form-field-suffix .mat-icon {
    width: 1.5em;
  }

  .mat-mdc-form-field-suffix .mat-mdc-icon-button .mat-icon {
    height: 10.325em;
  }

  .model-preview-btn {
    display: flex;
    align-self: center;
    .mat-mdc-icon-button {
      width: 15px;
    }
  }
}

printable-data,
max-try {
  flex-direction: column;

  h2 {
    margin: 10px auto 10px auto !important;
  }

  .mat-mdc-list {
    flex: 1 1 0;
    overflow: auto;
    border: $touch-border;
    margin: 10px 0;
    @media (max-height: 700px) {
      height: 10rem;
    }
    @media (min-height: 701px) {
      height: 13rem;
    }

    .mat-mdc-list-item {
      border-bottom: $touch-border;
      padding-left: 0;

      .mdc-list-item__primary-text {
        display: flex;
        align-items: center;
        flex-direction: row;
      }
    }
  }
}

.btns {
  flex: 0 0 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.models-list {
  min-width: 200px !important;
  position: absolute !important;
  left: -14px !important;
  .mat-mdc-option {
    padding: 0 12px;
  }
}

.right-menu-tablet {
  flex: 1;
  height: inherit;
}
