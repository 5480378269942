connect-wifi-form {
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px;

    mat-form-field {
      width: 95%;
    }
  }
}
