//@import '@swimlane/ngx-datatable/release/index.css';

@import 'themes/initial-style';
@import 'themes/login-page';
@import 'themes/login-form';
@import 'themes/_toolbar';
@import 'themes/top-menu';
@import 'themes/user-menu';
@import 'themes/right-menu';
@import 'themes/user-details-dialog';
@import 'themes/selection-filter';
@import 'themes/password';
@import 'themes/assistance-dialog';
@import 'themes/reset-request-dialogs';
@import 'themes/ngx-datatable';
@import 'themes/model-preview';
@import 'themes/printable-data-selection-dialog';
@import 'themes/dashboard';
@import 'themes/spinner';
@import 'themes/model-preview-dialog';
@import 'themes/connect-wifi-form';

// to be revised and new styles to be put here after login in order
@import 'themes/piece';
@import 'themes/editor';

// tempo to revise too where to put this style
.mat-mdc-menu-content {
  display: inline-grid;
}

.mat-mdc-form-field-infix {
  width: auto !important;
}
