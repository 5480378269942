@use '@angular/material' as mat;
dashboard .mat-toolbar {
  padding: 0 0 0 0;
  background-color: map-get($touch-background, 50);
  border-bottom: 1px solid;

  .mdc-text-field--filled {
    background: transparent;
  }

  .mat-mdc-form-field-icon-suffix {
    align-self: flex-end;
    line-height: 25px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
