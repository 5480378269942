.btn-wrapper {
  display: flex;
  justify-content: center;
  margin: auto;
}
.spinner-wrapper {
  margin-left: 15px;
  width: 25px;
  height: 25px;
}

.mat-mdc-progress-spinner {
  width: 23px !important;
  height: 23px !important;
  svg {
    width: 23px !important;
    height: 23px !important;
  }
}

.no-table-content {
  display: flex;
  justify-content: center;
  height: 250px;

  .mat-mdc-progress-spinner {
    width: 40px !important;
    height: 40px !important;
    svg {
      width: 40px !important;
      height: 40px !important;
    }
  }
}

model-preview-dialog {
  .mat-mdc-progress-spinner {
    width: 50px !important;
    height: 50px !important;
    svg {
      width: 50px !important;
      height: 50px !important;
    }
  }
}

login-form {
  .mat-mdc-progress-spinner {
    margin: auto;
  }
}

.btns,
user-menu-dumb {
  .mat-mdc-progress-spinner {
    margin: auto;
  }
}

.assistance-no-content {
  align-items: center;
  height: 360px;
}

.mat-tab-label {
  .mat-mdc-progress-spinner {
    margin-left: 10px;
  }
}

.hide-spinner {
  display: none !important;
}
