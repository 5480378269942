user-menu-smart {
  display: flex;
  margin: auto;
}

user-menu-dumb {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  a {
    color: initial;
  }
}
