user-details-dialog {
  .mat-mdc-dialog-content {
    max-height: 90%;

    .mat-mdc-tab-group .mat-mdc-tab-body-wrapper .mat-mdc-tab-body-content {
      profil-tab {
        form {
          padding-top: 5px;
          overflow: hidden;
          .mat-mdc-form-field-infix {
            height: 30px;
            min-height: min-content;
          }

          .mdc-text-field--filled {
            background: transparent;
            padding-bottom: 8px;
          }

          .mat-mdc-form-field {
            width: 45%;
            margin-right: 1%;
            margin-left: 1%;
          }
        }
      }

      raspberry-wifi-log raspberry-wifi-log-action {
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
      }

      printing-log printing-log-filter form,
      credit-log credit-log-filter form {
        display: flex;
        align-items: baseline;
        overflow: hidden;
        .mat-mdc-form-field-infix {
          height: 30px;
          min-height: min-content;
        }

        .mdc-text-field--filled {
          background: transparent;
          padding-bottom: 8px;
        }

        .mat-mdc-form-field {
          line-height: 2.2;
          margin-right: 1%;
          margin-left: 1%;
          align-self: center;
        }
        .mat-mdc-form-field-icon-suffix {
          align-self: flex-start;
        }
        .mat-mdc-form-field-flex {
          height: 30px;
          min-height: min-content;
        }
        .mat-mdc-mini-fab {
          align-self: center;
          margin-left: 1%;
        }
      }

      plotter-tab {
        form {
          padding-top: 5px;
          overflow: hidden;
          .mat-mdc-form-field-infix {
            height: 30px;
            min-height: min-content;
          }

          .mdc-text-field--filled {
            background: transparent;
            padding-bottom: 8px;
          }

          .mat-mdc-form-field {
            width: 45%;
            margin-right: 1%;
            margin-left: 1%;
          }
        }
      }
    }
  }
}

.mat-mdc-dialog-actions {
  float: right;
}
