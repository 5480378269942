@use '@angular/material' as mat;

.top-menu {
  width: calc(100% - 223px);
  font-size: mat.m2-font-size($touch-typography, body-1) * 0.8;
  height: 100%;

  display: flex;
  flex-direction: row;
  border-right: $touch-border;
  padding-top: 1px;
  justify-content: center;

  @media (min-width: 960px) and (max-width: 1279.98px) {
    // Tablet devices
    width: calc(100% - 271px);
  }

  .mat-mdc-form-field {
    font-size: mat.m2-font-size($touch-typography, body-1);
    align-self: flex-end;
  }

  plotter-menu {
    display: flex;
    flex-direction: row;
    width: 52%;

    form {
      display: flex;
      width: 100%;
      align-items: center;

      .mat-mdc-text-field-wrapper {
        padding: 0;
      }

      .mat-mdc-form-field {
        margin: 0 3px;
        width: 34%;
        max-width: 200px;
        .mat-mdc-select-arrow {
          padding-top: 28px;
          align-self: flex-start;
        }

        .mat-mdc-form-field-flex {
          height: 42px;
        }
      }
    }
    .film-width {
      width: 18% !important;
    }
  }

  input[type='number']::-webkit-inner-spin-button {
    opacity: 1;
  }
}

.box-size {
  width: 130px;
  min-width: 112px !important;
}

.text-disabled {
  color: map-get($touch-background, 300);
}
