reset-password-dialog {
  form {
    .mat-mdc-form-field {
      width: 100%;
    }

    .mdc-text-field--filled {
      background: transparent;
      padding-bottom: 8px;
    }

    .mat-mdc-form-field-infix {
      height: 30px;
      min-height: min-content;
    }
  }
}

reset-password-form,
request-reset-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: auto;
  margin-top: 15%;
  padding: 1%;
  background-color: $login-form-background;
  box-shadow: 0px 2px 2px map-get($touch-background, 200);
  border-radius: 3px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    padding-top: 20px;

    .mat-mdc-raised-button {
      margin-top: 7%;
      width: 100%;
    }

    .mat-mdc-form-field {
      width: 100%;
    }

    .mdc-text-field--filled {
      background-color: transparent !important;
      padding-bottom: 8px;
    }

    .mat-mdc-form-field-infix {
      height: 30px;
      min-height: min-content;
    }
  }
}
