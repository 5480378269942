@use '@angular/material' as mat;
model-preview {
  display: flex;
  flex-direction: row;
  height: 100%;

  .model-details {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 20px;

    h3 {
      margin-bottom: 6px;
    }
    .image-wrapper {
      height: 200px;
      img {
        height: 100%;
      }
    }

    .model-image {
      align-items: center;
      height: 200px;
      width: 100%;
      display: flex;
      justify-content: center;

      .mat-mdc-progress-spinner,
      .mat-mdc-progress-spinner svg {
        width: 100px !important;
        height: 100px !important;
      }
    }

    p {
      font-family: mat.m2-font-family($touch-typography);
      font-size: mat.m2-font-size($touch-typography, body-1) * 1.2;
      font-weight: mat.m2-font-weight($touch-typography, body-1);
      height: 60px;
      overflow-y: auto;
      margin: 5px 0 0 0;
    }
  }

  .model-types {
    flex: 2;
    display: flex;

    .mat-mdc-tab-group {
      width: 100%;

      .mat-mdc-tab-body-wrapper {
        height: 320px;
      }

      .mat-mdc-tab-body-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        img {
          width: auto;
          margin: 2%;
        }

        .image-spinner {
          align-items: center;
          height: 300px;
          width: 100%;
          display: flex;
          justify-content: center;

          .mat-mdc-progress-spinner,
          .mat-mdc-progress-spinner svg {
            width: 100px !important;
            height: 100px !important;
          }
        }
      }
    }
  }
}
