login-page {
  display: flex;
  flex-direction: column;
  .logo {
    margin: auto;
    margin-bottom: 15px;
    img {
      width: 180px;
    }
  }

  .install-button {
    display: flex;
    align-self: center;
    padding-bottom: 15px;
    font-size: 20px;
    width: auto;
    .mat-mdc-raised-button {
      width: 42vw;
    }
  }
  .version {
    display: flex;
    align-self: flex-end;
    padding-top: 5px;
    padding-right: 7px;
    font-size: 16px;
  }

  .additional-informations {
    display: flex;
    flex-direction: row;
    width: 50%;
    margin: 2% auto;
    justify-content: center;
    text-align: center;
  }

  @media (max-height: 700px) {
    .logo {
      margin-bottom: 0px;
      img {
        width: 165px;
      }
    }
  }
}
