@use '@angular/material' as mat;
@import '../themes/palette';

@include mat.core();

$touch-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $touch-primary,
      accent: $touch-accent,
      warn: $touch-warn,
    ),
  )
);

$touch-typography: mat.m2-define-typography-config(
  $font-family: 'Arial',
  $body-1: mat.m2-define-typography-level(14px, 14px, normal),
  $caption: mat.m2-define-typography-level(14px, 14px, normal),
  $button: mat.m2-define-typography-level(12px, 12px, normal),
);

$default-link: map-get($touch-primary, 500);
$default-font: mat.m2-font-family($touch-typography);

$login-form-background: rgba(255, 255, 255, 1);
$main-background: rgba(188, 188, 188, 0.27);
$touch-border: 1px solid #c3c1c1;

@include mat.all-component-themes($touch-theme);
@include mat.all-component-typographies($touch-typography);
@include mat.form-field-density(-1);

html,
body {
  height: 100%;
}

app-root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

body {
  font-family: $default-font;
  margin: 0;

  a {
    color: $default-link;
  }
}

.background {
  background-color: lighten(map-get($touch-background, 100), 20%);
  height: 100%;
  overflow: hidden;
}
