@use '@angular/material' as mat;
@use '../../node_modules/@swimlane/ngx-datatable/themes/material.scss';
@use '../../node_modules/@swimlane/ngx-datatable/assets/icons.css';

.ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell {
  line-height: 2;
}

.ngx-datatable {
  font-family: mat.m2-font-family($touch-typography);
  font-size: mat.m2-font-size($touch-typography, body-1);
  font-weight: mat.m2-font-weight($touch-typography, body-1);
  border-radius: 3px;

  .datatable-row-odd {
    background-color: lighten(map-get($touch-background, 100), 18%);
  }
}

.table-wrapper {
  margin: 1%;
}

.ngx-datatable.material .datatable-footer {
  font-family: mat.m2-font-family($touch-typography);
  font-size: mat.m2-font-size($touch-typography, body-2);
  font-weight: mat.m2-font-weight($touch-typography, body-2);
  height: 41px;
}
