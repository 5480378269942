@use '@angular/material' as mat;
login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: auto;
  padding: 1%;
  background-color: $login-form-background;
  box-shadow: 0px 2px 2px map-get($touch-background, 200);
  border-radius: 3px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    padding-top: 20px;

    .mat-mdc-raised-button {
      margin-top: 7%;
      width: 100%;
    }

    .mat-mdc-form-field {
      width: 100%;
    }

    .mdc-text-field--filled {
      background-color: transparent !important;
      padding-bottom: 8px;
    }

    .mat-mdc-form-field-infix {
      height: 30px;
      min-height: min-content;
    }

    .mat-mdc-checkbox {
      align-self: start;
    }
  }

  .showPassword {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  a {
    align-self: flex-end;
    margin-top: 7%;
    font-size: mat.m2-font-size($touch-typography, body-1) * 0.9;
    font-weight: mat.m2-font-weight($touch-typography, body-2);
    cursor: pointer;
  }

  @media (max-height: 700px) {
    form {
      padding-top: 0px;

      .mat-mdc-raised-button {
        margin-top: 5%;
      }
    }

    a {
      margin-top: 5%;
    }
  }
}
