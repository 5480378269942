editor {
  display: flex;
  flex-direction: column;
  background: #d6d6d6;
  position: relative;
  min-width: calc(100% - 220px);
  margin-top: 5px;

  .exceed-x {
    background-color: darkred !important;
  }

  #wrapper {
    position: relative;
    display: flex;
    height: 100%;

    .exceed-y {
      background-color: darkred !important;
    }
  }

  .editor {
    position: relative;
    width: calc(100% - 25px); //tempo: fix dnd positioning
    overflow-x: auto;
    overflow-y: auto;
    z-index: 1000;

    piece {
      mix-blend-mode: difference;
    }
  }

  .top-border {
    background-color: #d6d6d6;
    position: absolute;
    top: 0;
    left: 25px;
    width: calc(100% - 26px);
  }

  .bottom-border {
    background-color: #d6d6d6;
    position: absolute;
    left: 25px;
    width: calc(100% - 26px);
  }
} // tempo

.ruler-x-dialog {
  height: 0;
  margin-left: 0;
}

.selection-zone {
  position: absolute;
  border: #0823b7 0.5px solid;
  background-color: #9eacf5;
}

.ruler-indicator {
  position: absolute;
  border-top: $default-link 1px solid;
  width: 100%;
  text-align: right;
  height: 0;
  background: transparent;
  font-size: 11px;
  color: $default-link;
}
.ruler-handler {
  position: absolute;
  width: 0;
  height: 0;
  border-left: solid 10px $default-link;
  border-bottom: solid 10px transparent;
  border-top: solid 10px transparent;
  cursor: ns-resize;
  opacity: 0.6;
}
