.container {
  display: flex;
  height: calc(100% - 139px);
}
.banner {
  img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
  }
}

dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
}
