piece {
  div {
    position: absolute;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;

    .selected {
      box-shadow: #3f51b5 0em 0em 0.5em;
    }

    .exceed {
      filter: sepia() saturate(10000%) hue-rotate(30deg);
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
}
