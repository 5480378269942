@use '@angular/material' as mat;
change-password,
reset-password {
  h3 {
    padding-top: 10px;
  }

  form {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    overflow: hidden;
    .mdc-text-field--filled {
      background: transparent !important;
      padding-bottom: 8px;
    }

    .mat-mdc-form-field-infix {
      height: 30px;
      min-height: min-content;
    }

    .mat-mdc-form-field {
      width: 50%;
    }

    .mat-mdc-raised-button {
      align-self: baseline;
      margin-top: 10px;
    }

    .mismatch-error {
      font-size: mat.m2-font-size($touch-typography, body-1) * 0.75;
      margin-top: -9px;
    }
  }
}
